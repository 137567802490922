<template>
  <v-container
    fluid
    class="pt-0 px-0"
    v-if="items.length"
  >
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      class="mb-5"
      label="Procurar no histórico de ajustes"
      hide-details
      single-line
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Linhas'
      }"
      :options="{
        itemsPerPage
      }"
      no-data-text="Nenhum log encontrado"
    >
      <template v-slot:[`item.tabela`]="{ item }">
        <span
          :class="{
            alerta: item.acao === 'update',
            perigo: item.acao === 'delete',
            sucesso: item.acao === 'insert'
          }"
        >
          {{ item.tabela | toUpperCase }} ({{ item.acao | toUpperCase }})
        </span>
      </template>
      <template v-slot:[`item.dado_antes`]="{ item }">
        <span v-if="item.acao === 'delete'">
          <v-btn
            color="gray"
            min-width="0"
            class="px-1"
            fab
            icon
            x-small
            @click="openDialogDeletedRow(item)"
          >
            <v-icon small>mdi-eye-outline</v-icon>
          </v-btn>
        </span>
        <span v-else>
          {{ item.dado_antes | treatDinamic(item.tipo_campo) }}
        </span>
      </template>
      <template v-slot:[`item.dado_depois`]="{ item }">
        <span v-if="item.acao === 'insert'">
          <v-btn
            color="gray"
            min-width="0"
            class="px-1"
            fab
            icon
            x-small
            @click="openDialogInsertedRow(item)"
          >
            <v-icon small>mdi-eye-outline</v-icon>
          </v-btn>
        </span>
        <span v-else>
          {{ item.dado_depois | treatDinamic(item.tipo_campo) }}
        </span>
      </template>
      <template v-slot:[`item.data`]="{ item }">
        {{ item.data | parseToDateTimeBR }}
      </template>
    </v-data-table>
    <dialog-detalhes
      :title="`${dialogViewRowData.tabela.toUpperCase()} :: Linha #${dialogViewRowData.id}`"
      :dialog.sync="dialogViewRow"
      v-if="dialogViewRow"
    >
      <template slot="body">
        <v-simple-table
          class="ml-3"
          height="400px"
          fixed-header
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Campo</th>
                <th class="text-left">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in dialogViewRowData.dado"
                :key="index"
              >
                <td>{{ item.campo }}</td>
                <td>{{ item.valor }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </dialog-detalhes>
  </v-container>
</template>

<script>
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';
import AtualizacoesTabelasService from '../../../services/AtualizacoesTabelasService';

export default {
  name: 'LogsAjustesManuaisEntidadesBdgdDataTable',
  components: {
    DialogDetalhes: () => import('@/components/general/DialogDetalhes')
  },
  mixins: [camposFiltrosMixins],
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    limit: {
      type: Number,
      default: 1000
    },
    tipo: {
      type: String,
      default: 'dda',
      validator: function (value) {
        return ['dda', 'mcpse'].includes(value);
      }
    }
  },
  data: () => ({
    search: '',
    items: [],
    loading: false,
    headers: [
      {
        text: '#',
        value: 'gid'
      },
      {
        text: 'Tabela',
        value: 'tabela'
      },
      {
        text: 'Campo',
        value: 'campo'
      },
      {
        text: 'Antes',
        value: 'dado_antes'
      },
      {
        text: 'Depois',
        value: 'dado_depois'
      },
      {
        text: 'Ajustado por',
        value: 'nome_usuario'
      },
      {
        text: 'Ajustado em',
        value: 'data'
      }
    ],
    dialogViewRow: false,
    dialogViewRowData: {}
  }),
  created() {
    this.getLogsAjustes();
  },
  methods: {
    getLogsAjustes() {
      this.loading = true;
      AtualizacoesTabelasService.logs(this.tipo, this.limit)
        .then(({ data }) => (this.items = data))
        .catch(() =>
          this.$toast.error(
            `Erro ao recuperar logs de ajustes efetuados nas tabelas de ${this.tipo.toUpperCase()}.`,
            '',
            { position: 'topRight' }
          )
        )
        .finally(() => (this.loading = false));
    },
    buildCampos(dado) {
      const ignorarCampos = this.getIgnorarCamposFiltros();
      const objetoDado = JSON.parse(dado);
      return Object.entries(objetoDado)
        .filter((item) => !ignorarCampos.includes(item[0]))
        .map((item) => {
          const [campo, valor] = item;
          return {
            campo,
            valor
          }
        });
    },
    openDialogDeletedRow({ gid, tabela, dado_antes }) {
      this.dialogViewRowData = {
        id: gid,
        tabela,
        dado: this.buildCampos(dado_antes)
      };
      this.dialogViewRow = true;
    },
    openDialogInsertedRow({ gid, tabela, dado_depois }) {
      this.dialogViewRowData = {
        id: gid,
        tabela,
        dado: this.buildCampos(dado_depois)
      };
      this.dialogViewRow = true;
    }
  }
};
</script>

<style scoped>
.alerta {
  color: #ffa726;
  font-weight: 400;
}

.perigo {
  color: #e53935;
  font-weight: 400;
}

.sucesso {
  color: #2ba327;
  font-weight: 400;
}
</style>
